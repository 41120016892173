import { Module } from 'vuex';
import { RootState, DownloadState } from '@/types/state';
import getters from './getters';
// eslint-disable-next-line import/no-cycle
import actions from './actions';
import mutations from './mutations';

function defaultState(): DownloadState {
  return {
    finishedDownloads: [],
    nextId: 1,
    downloads: [],
  };
}

const module: Module<DownloadState, RootState> = {
  namespaced: true,
  state: defaultState(),
  getters,
  actions,
  mutations,
};

export default module;
