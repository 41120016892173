import { GetterTree } from 'vuex';
import { RootState, DownloadState } from '@/types/state';

const getters: GetterTree<DownloadState, RootState> = {
  progress: (s) => (id: number): [number, number] => [
    s.downloads[id]?.total || 1,
    s.downloads[id]?.loaded || 0,
  ],
  download: (s) => (id: number) => s.downloads[id],
  list: (s) => s.downloads.filter((d) => !!d),
  listByCommissionId: (s) => (c: string) => s.downloads.filter((d) => d.commissionId === c),
  listByBusinessId: (s) => (c: string) => s.downloads.filter((d) => d.businessId === c),
};

export default getters;
