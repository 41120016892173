import { ActionTree } from 'vuex';
// eslint-disable-next-line import/no-cycle
import {
  RootState, DownloadState, Download, DownloadAction,
} from '@/types/state';
import Axios, { AxiosResponse } from 'axios';

const actions: ActionTree<DownloadState, RootState> = {
  async download(
    { commit, dispatch },
    {
      name, type, size,
      downloadUrl, onSuccess, onCancel, onError,
      routerTo, businessId, commissionId,
    }: DownloadAction,
  ): Promise<number> {
    // TODO: Use some uniqid to prevent downloading same file twice?

    const id: number = await dispatch('getNextId');

    const onDownloadProgress = (event: ProgressEvent): void => {
      commit('progress', [id, event.total, event.loaded]);
    };

    const cancelTokenSource = Axios.CancelToken.source();

    const downloadRequest = (url: string): Promise<AxiosResponse> => Axios.request({
      method: 'GET',
      url,
      onDownloadProgress,
      cancelToken: cancelTokenSource.token,
      validateStatus(status) {
        return status === 200;
      },
      responseType: 'blob',
    }).catch((err) => {
      if (Axios.isCancel(err) && onCancel) onCancel();
      throw err;
    });

    let promise: Promise<AxiosResponse<Blob>>;

    if (downloadUrl instanceof Promise) {
      promise = downloadUrl.then(downloadRequest);
    } else {
      promise = downloadRequest(downloadUrl);
    }

    promise.then((r: AxiosResponse) => {
      if (onSuccess) return onSuccess(r.data);
      return r;
    }).then((res) => {
      commit('done', id);

      return res;
    }).catch((err: Error) => {
      console.error(err);

      commit('error', [id, err]);

      if (Axios.isCancel(err) && onCancel) return;

      if (onError) onError(err);

      throw err;
    });

    const download: Download = {
      id,
      name,
      type,
      promise: promise.then((r) => r.data),
      async getBlobUrl(): Promise<string> {
        if (!this.blobUrl) {
          this.blobUrl = URL.createObjectURL(await this.promise);
        }

        return this.blobUrl;
      },
      revokeBlobUrl() {
        if (this.blobUrl) {
          URL.revokeObjectURL(this.blobUrl);
          delete this.blobUrl;
        }
      },
      total: size,
      loaded: 0,
      cancel: (message?: string) => {
        commit('cancel', [id, message]);
      },
      cancelToken: cancelTokenSource,
      routerTo,
      businessId,
      commissionId,
    };

    commit('set', download);

    return id;
  },

  getNextId({ commit, state }) {
    const id = state.nextId;

    commit('incrementId');

    return id;
  },
};

export default actions;
